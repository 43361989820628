.button-group-tab {
    border-bottom: 1px solid #dddddd;
    width: 100%;
    border-radius: 0;
    padding: 15px 0 0;
} 
.MuiButtonGroup-root .MuiButtonGroup-grouped {
    color: var(--color-3);
    font-family: ActualM;
    text-transform: capitalize;
    border: 1px solid #ddd;
    border-radius: 0 16px 0 0;
}
.Analysis-section{
    background:white;
    padding:20px;
}
.tab-active {
    color: white !important;
    background: var(--color-2) !important;
}