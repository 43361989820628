/* CSS Style Definitions/Classes for Scheduler - Staff Bulk Edit Screens*/
#scheduler-bulk-edits {


    //     .display-times-button-group button:first-child {

    //         color: #fff;
    //     background-color:#E5E5E5;

    // }
    .add-user-section {
        justify-content: flex-end;

        .MuiButtonBase-root.MuiButton-root:hover,
        button:hover {
            color: white;
        }
    }

    .search-bar {
        border-radius: 27px;
        margin-bottom: 10px;
        font-family: ActualR;
        padding: 5px 20px 5px 25px;
        width: 100%;
        max-width:300px;
    }

    .edit {
        cursor: pointer;
        color: blue;

    }

    .icon-search1 {
        position: absolute;
        margin: 10px 0 0 10px;

    }

}

#editEvents {
    font-family: ActualR;
    margin-top: 20px;
    text-align: center;


}

.display-times-button-group button {
    margin: 0 10px;
    margin-bottom: 10px;
}

.MuiDataGrid-root {
    background-color: #fff;
}

.top-header {
    padding: 12px 16px;
    background-color: var(--color-1);
    color: white;
    border-radius: 4px 4px 0 0;
    margin-top: 20px;
}

// .display-times-button-group button:first-child {
//     background-color: #ddd;
//     color: black;
// }

.display-times-button-group button{
    height:40px;
}

#edit_event {
    background-color: #fff;
    padding: 10px 15px 15px;

    .add-user-section {
        justify-content: flex-end;

        .MuiButtonBase-root.MuiButton-root:hover,
        button:hover {
            color: white;
        }
    }


}

#input_email {
    width: 100%;
    height:30px;
}
.display-time-input-phone{
    width: 100%;
    height:30px;
}
.display-time-input-phone::after{
    border: none !important;
}
.display-time-input-phone::before{
    border: none !important;
}

input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; 
        display: none;
    }

#edit_event_channel {
    background-color: #fff;
    padding: 10px 15px 15px;

    .add-user-section {
        justify-content: flex-end;

        .MuiButtonBase-root.MuiButton-root:hover,
        button:hover {
            color: white;
        }
    }

}

#channer_partner {
    .MuiFormControl-root {
        width: 276px;
        margin: 3px;
    }

    .MuiInputBase-root-MuiOutlinedInput-root {
        height: 33px;
    }

    #mm {
        margin-bottom: 20px;

    }

    #copy_button {
        text-transform: capitalize;
        height: 31px;
        /* margin-bottom: 21px; */
        min-width: 68px;
        margin-top: 1px;

    }

}
.display-times-button-group button:hover {
    color:white;
}