#footer-component{
    padding:20px;
    background: white;
    // text-align:center;
    position: fixed;
    bottom: 0;
    margin-bottom: 0;
    z-index: 2;
    width: 100%;
    p{
        font-family:ActualR;
        font-size:12px;
        margin:0;
    }
}