.partnerUtilityAPI-configurator {
    margin-top: 8px;

    .inner-container {
        padding: 16px 32px 32px;

        .info-cont {
            // h2 {
            //     margin-bottom: 8px;
            // }

            p {
                // max-width: 50%;
                margin-bottom: 16px;
            }
        }

        .input {
            display: flex;
            align-items: center;
            margin-bottom: 24px;

            .name-text {
                margin: 0 0 4px 0;
                width: max-content;
                margin-right: 16px;
                font-family: actualM;
            }

            .searchText {
                border: 1px solid #aaa;
                padding: 0 0 0 4px;
                width: 25%;
                height: 30px;
                border-radius: 4px
            }
        }

        .table-parent {
            // height: auto;
            width: 100%;

            .no-result {
                height: 50vh;
                border: 1px solid #aaa;
                display: flex;
                justify-content: center;
                align-items: center;

                p {
                    font-family: actualM;
                    font-size: 16px;
                }
            }
        }

        .Mui-selected {
            background-color: var(--color-2);
        }

        .MuiDataGrid-main {
            div:nth-of-type(2) {
                height: auto !important;
            }

            .MuiDataGrid-columnHeaderTitle {
                font-family: actualM;
            }
        }
        .MuiDataGrid-virtualScroller{
            overflow: auto !important;
            height:auto !important;
        }
        .MuiDataGrid-menuIcon{
            button{
                display: none;
            }
        }
    }
}