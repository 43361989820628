#template_list{
    .outer-box{
        box-shadow: 0 4px 15px 0 rgba(102, 102, 102, 0.2);
        background-color: #fff;
        border-radius: 4px;
          .top-header{
          padding: 12px 16px;
          background-color: var(--color-1);
          color:white;
          border-radius: 4px 4px 0 0;
       }
    }
    .history-log-section{
      padding: 0px 20px 20px 20px;

    }
    
    .filtersSection{
        display: flex;
        align-items: center;
        
    
      }
      .filterLabel{
        font-family: actualM;
        font-size:13px;
        margin-bottom: -14px;
      }
      .editsearch{
        border: 1px solid #aaa;
        border-radius: 4px;
        color: white;
        font-family: ActualR;
        background-color:  var(--color-2);
        margin-bottom: 0px;
      }
      .deletesearch{
        border: 1px solid #aaa;
        border-radius: 4px;
        color: white;
        font-family: ActualR;
        background-color:  var(--toastify-color-error);
        margin-bottom: 0px;
      }
      .input{
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
      }

      .searchText{
        padding: 5px;
       
        width: 300px;
      }
      .header-content-title{
        margin-right: 28px;
      }
      .btn-marg {
        margin: 0px;
      }
      .part2{
        display: flex;
      }

   .header-content-title_1{
    margin-right: 28px;
    
   }
   .select{
    .MuiOutlinedInput-root {
      width: 316px;
      height: 34px;
    
    }
    .MuiOutlinedInput-notchedOutline{
      border-color:black!important;
    }
    .notchedOutline{
      border-color:black!important;
    }

   }

   .updateclass{
    display: flex;
    justify-content: center;
    margin: 10px;
   }
   .second_part{
    display: flex;
    justify-content: space-between;
    max-width: 655px;
    width: 100%;
   }
   .search{
    margin-top: 5px;
   }
   .search_input{
    height: 30px;
   }
   .overdesign{
    padding: 0px 20px 20px 20px;
   }
   .cancel_buttom_2{
    margin-top: 10px;
   }
}