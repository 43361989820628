.preloader {
    display: flex;
    align-items: center;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    background : black;
    opacity: 0.85;
    
    .loader1 {
        display:inline-block;
        font-size:0px;
        padding:0px;
     }
     .loader1 span {
        vertical-align:middle;
        border-radius:100%;
        
        display:inline-block;
        width:30px;
        height:30px;
        margin:3px 2px;
        -webkit-animation:loader1 0.8s linear infinite alternate;
        animation:loader1 0.8s linear infinite alternate;
     }
     .loader1 span:nth-child(1) {
        -webkit-animation-delay:-1s;
        animation-delay:-1s;
       background:rgba(245, 200, 230,0.6);  //
     }
     .loader1 span:nth-child(2) {
        -webkit-animation-delay:-0.8s;
        animation-delay:-0.8s;
       background:rgba(245, 200, 230,0.8);
     }
     .loader1 span:nth-child(3) {
        -webkit-animation-delay:-0.26666s;
        animation-delay:-0.26666s;
       background:rgba(245, 200, 230,1);
     }
     .loader1 span:nth-child(4) {
        -webkit-animation-delay:-0.8s;
        animation-delay:-0.8s;
       background:rgba(245, 200, 230,0.8);
       
     }
     .loader1 span:nth-child(5) {
        -webkit-animation-delay:-1s;
        animation-delay:-1s;
       background:rgba(245, 200, 230,0.4);
     }

    &.show {
        height: 0;

        .loader1{
            display: none !important;
        }
    }
}

 
 @keyframes loader1 {
    from {transform: scale(0, 0);}
    to {transform: scale(1, 1);}
 }
 @-webkit-keyframes loader1 {
    from {-webkit-transform: scale(0, 0);}
    to {-webkit-transform: scale(1, 1);}
 }