.MuiDataGrid-root.MuiDataGrid-columnHeaderTitleContainer{
    justify-content: center;
}


.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft,
.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textRight, 
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft, .MuiDataGrid-cell.MuiDataGrid-cell--textRight{
    justify-content: center;

}

    .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft.left-align,
.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textRight.left-align, 
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft, .MuiDataGrid-cell.MuiDataGrid-cell--textRight.left-align,
.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.left-align, .left-align{
    justify-content: flex-start;
    padding:0 16px;

}
.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft.center-align,
.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textRight.center-align, 
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft, .MuiDataGrid-cell.MuiDataGrid-cell--textRight.center-align,
.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.center-align, .center-align{
    justify-content: center;

}
.MuiDataGrid-footerContainer{
    .MuiDataGrid-selectedRowCount{
        visibility: hidden;
    }
}

div[role="tooltip"].MuiDataGrid-panel {
    top: -60px !important;
    .MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked, .MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked{
        color: var(--color-2) !important;
    }
  }


  