// .jsoneditor-menu{
//     // background-color: var(--color-1) !important;
//     // border-bottom: 1px solid var(--color-1) !important;
//     color:white;
//     // display:none;
// }
.jsoneditor-menu li button.jsoneditor-selected, .jsoneditor-menu li button.jsoneditor-selected:hover{
        background-color: var(--color-1) !important;
        color: white;

}
// .pico-modal-header{
//     background-color: var(--color-1) !important;
//     color:white;

// }

.jsoneditor{
    border: 1px solid var(--color-1);
.jsoneditor-menu{
        background-color: var(--color-1);
        border: 1px solid var(--color-1);
    color:white !important;
    .jsoneditor-transform, .jsoneditor-sort, .jsoneditor-undo, .jsoneditor-redo{
        display:none
    }
}
}