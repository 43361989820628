.screen-outer{
    // background:black;
    width:100%;
    height:100%;
    position:relative;
   .cont{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:20px;
    .unplanned-data{
        display:flex;
        align-items:center;
        justify-content:center;
        .text{
            font-family:'ActualR';
            margin: 0 30px 0 0;
            max-width:400px;
        }
        img{
            width:100%;
            height:100%;
            max-width:400px;
            max-height:300px;
            margin:0 0 0 30px;
        }
    }
}
.planned-cont{
    height: calc(100% - 103px);
    position: relative;
    background: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:20px;
} 
  .attention_msg{
      padding:15px 39px 6px 39px;
      background:black;
      color:white;
  }
  .popup_message{
    // display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: white;
    border-radius: 4px;
    max-width: 362px;
    .utility-img{
        text-align: center;
    margin-bottom: 10px;
        img{
            width:50px;
            height:50px;
        }
    }
    p.text{
        font-family: ActualR;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
    }
    .data-preview{
        max-height: 55vh;
        overflow-y: auto;
    }
    img{
        width:100%;
      }
  }
}