.home-dashboard{
    width:100%;
    background: #eee;
    height: 85vh;
.dashboard-landing{
        background: var(--color-1);
        height:198px;
        position: absolute;
        width: 100%;
    }
    .cont{
        padding: 36px 40px 0;
    }
     .cards{
        //  height:107px;
         width: 205px;
         border-radius: 16px;
         background:white;
         .card-body{
            img{
                width:40px;
                height:40px;
                margin:auto;
            }
            p.text{
                font-family:ActualM;
                font-style: normal;
                font-size: 14px;
                color: var(--color-4);
                margin-bottom:0;
                margin-top:5px;
                text-transform: capitalize;
            }

         }
     }
     .welcome-user{
        font-family: ActualR;
        font-style: normal;
        color: #FFFFFF;
        text-transform: capitalize;;
        max-width:1240px;
        margin:auto;
        margin-top:48px;
        width:100vw;
        padding:0 20px;
        .greeting{
            font-size: 28px;
        }
        .text{
            font-size: 12px;
        }
        .time-format{
            margin-top:10px;
            div{
                margin:0 10px;
                i{
                    margin-right:5px;
                }
            }
        }
     }
}
.card {
    cursor: pointer;
    box-shadow: 2px 2px 2px grey;

}

.card:hover {
    box-shadow: 4px 4px 4px grey;
}

.mainLayout-component{
    width:auto;
    min-height:88vh;
}

@media only screen and (max-width: 480px){
    .home-dashboard{
        height: auto;
         .cards{
             height:auto;
             width: 20rem;
             .card-body{
                 display:flex;
                 justify-content: flex-start;
                 align-items:center;
                 img{
                    height:60px;
                    margin: 0 30px 0 0;
                }

             }
         }
    }
}