.btn-mx-width{
    min-width:300px;
    max-width:300px;
    font-size: 14px;
}
.btn-marg{
    margin:20px 0;
    a{
        color:black;
        text-decoration:none;
        font-size: 14px;
    }
}
.dropdown .dropdown-menu{
    transform : translate3d(-1px, 32px, 0px) !important;
    height: 220px;
    overflow: hidden scroll;
    font-size: 14px;
    width: 100%;
    input{
        width: 85%;
    }
}
.long-menu {
    button{
       border-radius:50%;
       padding:4px;
       width:25px;
       height:25px;
    }
    .dropdown-item{
        font-family: ActualR, Roboto;
        font-size:14px;
    }
    .dropdown-toggle:empty::after {
        margin-left:auto;
    }
    .dropdown-toggle::after {
        content: '\2807';
        font-size: 12px;
        border: none;
        display:flex;
        justify-content: center;
        padding-left:2px;
   }
}  