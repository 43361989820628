.tox .tox-editor-container{
    font-family:'ActualR';
}
.tox:not([dir=rtl]) .tox-statusbar__branding{
    display: none !important;
}
.tox-statusbar{
    display: none !important;
}
.tox:not(.tox-tinymce-inline) .tox-editor-header {
    transition: box-shadow .5s;
    box-shadow: 0px 9px 27px #00000012,
     0px 3.75998px 11.28px #0000000d, 
     0px 2.01027px 6.0308px #0000000a,
      0px 1.12694px 3.38082px #0000000a,
       0px 0.598509px 1.79553px #00000008, 
       0px 0.249053px 0.747159px #00000005 !important;
}