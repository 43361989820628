// This is css is going to be used in tools -> errorLog -> errorList

#errorListContainer {
    .outer-box{
        padding-bottom: 10px;
    }
    .ErrorList {
        padding: 12px 16px;
        background-color: var(--color-1);
        color: white;
        border-radius: 4px 4px 0 0;
        margin-top: 8px;
    }

    .upper-filters,.filterContent {
        display: flex;
        align-items: center;
        margin: 0px 0px 0px 2px;

        input {
            border: 1px solid #aaa;
            padding: 0 0 0 4px;
            width: 100%;
            height: 38px;
            border-radius: 4px;
        }

        .error-filter-search-button {
            margin-top: 20px;
        }

        .btn:hover {
            color: #fff;
        }
        .platformFilter{
        }
        .MuiFormControl-root{
            margin-top: 0px;
        }
    }

    .date-container {

        input {
            border: 1px solid #aaa;
            padding: 0 0 0 4px;
            width: 85%;
            height: 33px;
            border-radius: 4px;
        }
    }

    .error-record {
        margin-top: 20px;

        .btn:hover {
            color: #fff;
        }
    }
    .platformLabel{
        margin-top: 0px;
    }
    // .MuiDataGrid-root .MuiDataGrid-row {
    //     break-inside: auto;
    //     height: 170px !important;
    //     word-break: break-all;
    //     max-height: 170px !important;
    // }
    // .MuiDataGrid-root .MuiDataGrid-cell {
    //     overflow: auto;
    //     text-overflow: unset;
    //     white-space: normal;
    //     max-height: 170px !important;
    //     height: 170px;
    //     min-height: 170px !important;
    //     word-break: break-all;
    // }
    .panel {
        font-family: 'ActualR';
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #666666;
    }
 
    .bill-pdf {
        font-family: 'ActualR';
        font-style: normal;
        font-size: 12px;
        letter-spacing: 0.25px;
        color: #666666;
        text-overflow: ellipsis;
        overflow: hidden;
        text-wrap: wrap;
    }
   
}