#staff-configuration-component{
    .add-user-section{
            width: 100%;
            display: flex;
            justify-content: flex-end;
            padding: 10px 0;
    }
    .outer-box{
        .MuiDataGrid-root{
            .grid-toolbar-cont{
                float:right;
                button{
                   color:var(--color-2);
                }
            }
        }
        p.filter-label{ 
            font-family:ActualM;
            font-size:13px;  
       } 
    }
    .MuiPaginationItem-root.Mui-selected{
        background-color: var(--color-2);
    }
    .MuiDialogContent-root{
        .MuiFormControlLabel-root, label{
            span{
            color:#333333 !important;
            }
        }
    }
    .MuiFormControlLabel-root .MuiFormControlLabel-label, label{
        color:#333333 !important;
    }

    label {
        color: #333333 !important;
        font-weight: bold;
        font-size: 18px;
        margin-top:10px;
    }
    .product-type-chip{
        .MuiChip-root{
            border: 1px solid var(--color-2);
            color:var(--color-2);
        }
    }
    .installer-list-checkbox{
      .MuiFormControlLabel-root .MuiFormControlLabel-label { color: #333333;}
    }
    .installer-filter-section{
        div{
            margin-right: 10px;
        }
        button{
            background: var(--color-2)

        }
        button:hover{
            color:white;
        }
        button.Mui-disabled{
            color:white;
            background: var(--color-3);
        }
        .btn-mx-width {
            min-width: 150px;
            max-width: 300px;
            font-size: 14px;
        }
    }
    .MuiDataGrid-virtualScroller{
        overflow: auto !important;
        height:auto !important;
    }
    .MuiDataGrid-menuIcon{
        button{
            display: none;
        }
    }
    // .MuiDataGrid-root .MuiDataGrid-cell--textCenter[data-field = "actions"]{
    //     justify-content: flex-start;
    // }
    .reset-filter{
        margin-left:10px;
        color:blue;
        cursor:pointer;

    }

    // .MuiDataGrid-root .MuiDataGrid-cell--textCenter:last-child {
    //     -webkit-box-pack: center;
    //     -ms-flex-pack: center;
    //     -webkit-justify-content: center;
    //     justify-content: center;
    //     position: sticky;
    //     right: 0;
    //     background: white;
    //     z-index: 1;
    //     overflow: hidden;
    //     box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f;
    //     float:right;

    // }
    // .MuiDataGrid-pinnedColumns-1, .MuiDataGrid-columnHeader MuiDataGrid-columnHeader--alignCenter:last-child{
    //     -webkit-box-pack: center;
    //     -ms-flex-pack: center;
    //     -webkit-justify-content: center;
    //     justify-content: center;
    //     position: sticky;
    //     overflow: hidden;
    //     z-index: 1;
    //     box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f;
    //     background-color: #fff;
    //     right: 0;
    // }
    button.staff-list{
        min-width:90px !important;
    }
    button:hover{
        color:white;
    }
    .MuiDataGrid-columnHeaderTitle{
        font-family:ActualM;
        font-weight:none;
    }

    button.button-view, span.button-view{
        background-color: #ddd;
        color: black;
    }

    .filter-section{
        align-items: end;
        flex-wrap:wrap;
        margin-bottom:15px;
        font-family: ActualR;
        font-size: 14px;
        input{
            height:31px;
        }
        .MuiFormControl-root{
            margin:10px 15px 0 0;
        }
        .MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiOutlinedInput-input.MuiInputBase-input{
            padding:7px;
            font-family: ActualR;
            font-size: 14px;
        }
        .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input{
            padding: 6px;
            min-width:50px;
            font-family: ActualR;
            font-size: 14px;
        }
        .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
            legend{
                display:none;
            }
        }
        .MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate,
        .MuiCheckbox-root.MuiButtonBase-root.Mui-checked{
            color:var(--color-2);
        }
    }
    .filter-search{
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 800px;
        width: 100%;
    }
    .MuiButtonBase-root-MuiMenuItem-root .MuiListItemText-root{
        .MuiTypography-root{
        font-family: ActualR;
        font-size: 14px;
        }
    }
    .MuiList-root.MuiList-padding.MuiMenu-list{
        .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root{
            padding: 0;
            font-family: ActualR;
            font-size: 14px;
        }
    }
}
.MuiButtonBase-root-MuiMenuItem-root .MuiListItemText-root{
    .MuiTypography-root{
    font-family: ActualR;
    font-size: 14px;
    }
}
.MuiList-root.MuiList-padding.MuiMenu-list{
    padding:8px;
    .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root{
        padding: 0;
        font-family: ActualR;
        font-size: 14px;
        height: 30px !important;
    }
}

.MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate,
.MuiCheckbox-root.MuiButtonBase-root.Mui-checked{
    color:var(--color-2);
}

.quicksight{
    width: 100%;
    height: 200px;
    text-align: center;
    border: 1px solid rgb(221, 221, 221);
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}