.projectList-container {
    margin-top: 8px;

    .outer-box {
        padding-bottom: 24px;
    }  
    .approve-action{
        color: #0288d1;
        font-size: 15px;
    } 
  

    .input {
        display: flex;
        align-items: center;
        margin: 20px;

        .name-text {
            margin: 0 0 4px 0;
            width: max-content;
            margin-right: 16px;
            font-family: actualM;
        }

        .searchText {
            border: 1px solid #aaa;
            padding: 0 0 0 4px;
            width: 27%;
            height: 33px;
            border-radius: 4px;
            margin-top: 8px;
        }
    }

    .no-result {
        height: 250px;
        width: 100%;
        margin: 20px;
        // border: 1px solid #aaa;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // background-color: #fff;
        p {
            font-family: actualM;
            font-size: 16px;
        }
        .back-projects{
            color: #007bff;
            text-decoration: underline;
            margin:16px 0 0 0;
            cursor: pointer;
        }
    }

    .MuiDataGrid-main {
        background: #fff;

        div:nth-of-type(2) {
            height: auto !important;
        }

        .MuiDataGrid-columnHeaderTitle {
            font-family: actualM;
        }
    }

    .MuiDataGrid-virtualScroller {
        overflow: auto !important;
        height: auto !important;
    }

    .Mui-selected {
        background-color: var(--color-2) !important;
    }

    .MuiDataGrid-row.Mui-selected {
        background-color: rgba(25, 118, 210, 0.08) !important;
    }

    .MuiDataGrid-toolbarContainer {
        justify-content: flex-end;

        button {
            color: var(--color-2);
        }
    }

    .MuiDataGrid-cell:focus {
        outline: none !important;
    }

    .MuiDataGrid-selectedRowCount {
        visibility: hidden;
    }

    .MuiDataGrid-cellContent {
        overflow: hidden !important;
        text-overflow: ellipsis;
    }

    .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft,
    .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textRight,
    .MuiDataGrid-cell.MuiDataGrid-cell--textLeft,
    .MuiDataGrid-cell.MuiDataGrid-cell--textRight {
        justify-content: left;

        div {
            overflow: hidden;
            text-overflow: ellipsis;
        }

    }
    .list-button-group {
        padding: 20px 20px 0 20px;
        display: flex;
        justify-content: space-between;    
        button {
            margin-left: 10px;
        }
    }
    button:hover{
        color:white;
    }

    .reset-filter {
        margin-left: 10px;
        color: blue;
        cursor: pointer;
    }

    .MuiDataGrid-menuIcon {
        button {
            display: none;
        }
    }
}
.fileUpload{


    .MuiOutlinedInput-root.MuiInputBase-root{
      width:400px;
      margin:10px 0;
      .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input{
       padding:10px 34px 10px 10px;
       border:1px solid grey;
      }
    }
}
.projectList-width{
.MuiDialog-container.MuiDialog-scrollPaper{
    .MuiPaper-root-MuiDialog-paper,
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24
    {
        width:100% !important;
        height:100% !important;
    }
    }
}

.MuiDialogContent-root {
    border-top: none !important;
}
.MuiTypography-root.MuiDialogTitle-root {
    height: 12px !important;
}
.MuiTypography-root.MuiDialogContentText-root{
    width:100%;
    height:100%
}
i.pms-font.pms-sign {
    font-size: 24px;
    line-height: 1.15;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  i.pms-sign:before {
    color: var(--color-1);
  }
.fileUpload-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height:90%;
    .uploadedImage {
        width: 100%;
        min-height: 100%;
        height: auto;
        border: 1px solid #aaaa;
        margin: auto;
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        .display-imagefile {
        //  height: 100%;
        //  width:100%;
         display: flex;
         align-items: center;
            img {   
                width: 100%;
                height: 70vh;
            }   

        }

    }
}
.downloadItems {
    // width:100%;
    // height:650px;
    display: flex;
    align-items: center;
    flex-direction: column;
    object{
        height:70vh;
        width:70vw;
    }
    button {
        border: 2px solid #2E7D32;
        height: 32px;
        color: #2E7D32;
        background-color: white;
        font-size: 12px;
    }
}

.button-control {
    .MuiIconButton-sizeSmall:hover {
        background-color: transparent;

        button.file-list-action:hover {
            background-color: transparent;
            color: #1976d2;
        }

        button:focus {
            box-shadow: none;
        }
    }
}

.file-filter {
    margin-left: 15px;
    display: flex;
    align-items: center;
}

p.filter-label {
    font-family: ActualM;
    font-size: 13px;
}

.filter-search-button {
    margin-bottom: 15px;
}

.filter-box {
    margin-bottom: 15px;

}