.channel-partner{
    box-shadow: 0 4px 15px 0 rgba(102, 102, 102, 0.2);
    background-color: #fff;
    border-radius: 4px;
    font-family: ActualR, Roboto;
    h5{
        color:#333333;
        font-size:14px;
    }
    p{
        color:#666666;
        font-size:12px;
    }
}
.channel-partner1{
    background-color: #fff;
    border-radius: 4px;
    font-family: ActualR, Roboto;
    h5{
        color:#333333;
        font-size:14px;
    }
    p{
        color:#666666;
        font-size:12px;
    }
}
.outer-box{
  box-shadow: 0 4px 15px 0 rgba(102, 102, 102, 0.2);
  background-color: #fff;
  border-radius: 4px;
    .top-header{
    padding: 12px 16px;
    background-color: var(--color-1);
    color:white;
    border-radius: 4px 4px 0 0;
    // margin-bottom:10px;
}
}