#installer-history-logs-component {
  .outer-box{
    box-shadow: 0 4px 15px 0 rgba(102, 102, 102, 0.2);
    background-color: #fff;
    border-radius: 4px;
      .top-header{
      padding: 12px 16px;
      background-color: var(--color-1);
      color:white;
      border-radius: 4px 4px 0 0;
   }
   .history-log-section{
    padding: 0 20px 20px;
   }
  }
  .installer-dropdown{
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .tab-active {
     color: white !important;
    background: var(--color-2);
  }
      
  .filtersSection{
    display: flex;
    align-items: center;

  }
  .filterLabel{
    font-family: actualM;
    font-size:13px;
    margin-bottom: -14px;
  }

  .filterContent{
    margin: 10px 35px 0px 0px;
  }
  .searchText {
    border: 1px solid #aaa;
  //  padding: 0 0 0 4px;
    width: 100%;
    height: 35px;
    border-radius: 4px
  }
  .searchText:focus-visible {
 outline: none !important;
  }

  .titleSearch{
    margin: 10px 0px 22px 0px;
  }
  .moduleText{
    margin-bottom: 8px;
  }
  .TitleSearch{
    margin: 16px 0px 0px 28px;;
  }
  .titleSearchButton{
    font-family: ActualR;
    border: 1px solid #aaa;
    border-radius: 4px;
    color: white !important;
    background: var(--color-2);
  }
  .lowerFilterSection{
    display: flex;
    column-gap: 21px;
  }
  .dateRangeCont{
    display: flex;
    margin: -11px 0px 0px 0px;
    .react-datepicker-wrapper {
      display: block !important; 
    }
  }
  input.dateInput {
    border: 1px solid #aaa;
    border-radius: 4px;
    height: 30px;
}
  .input-container-range{
    border: 1px solid #aaa;
    border-radius: 4px;
    height: 30px;
  }
  .datesearch{
    border: 1px solid #aaa;
    border-radius: 4px;
    height: 34px;
    color: white;
    font-family: ActualR;
    background-color:  var(--color-2);
  }
  .history-record{
    margin: 9px 0px 0px 0px;
  }
  .action-perfomred{
    .dropdown .dropdown-menu {
      height: 125px;
      overflow: hidden scroll;
      font-size: 14px;
      width: 100%;
  }
  }
  .no-result {
    height: 60vh;
    border: 1px solid #aaa;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        font-family: actualM;
        font-size: 16px;
    }
}

}