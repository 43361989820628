.manufacturer-edit-component{
  .upload{
    display: flex;
}
.image_shown{
    height: 45px;
    width: 100px;
    margin-top: -18px;
    margin-left: 17px;
    .image{
        width: 100%;
        height: 60px;
        
      
    }
}
  .section{
    background:white;
    padding:24px;
    .input-container{
        display: flex;
        align-items: center;
        margin-bottom:20px;
        p{
            font-family: ActualM;
            font-size:14px;
            width:300px;

        }
         input, select, .MuiInputBase-root .MuiOutlinedInput-root-MuiSelect-root {
            padding:8px;
            width: 280px;
            font-family: 'ActualR';
            font-size: 14px;
            border: 1px solid grey;
            background:white;
        }
        .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input{
            width:275px;
            padding: 8px 12px;
            border: 1px solid #696464;
        }
        .PrivateSwitchBase-input{
          width:45px
        }
        
    }
    .button-handler{
       width:100%;
       display:flex;
       justify-content:center;
       align-items:flex-start;
        button{
            margin: 0 10px;
        }
        button:hover{
            color:white !important;
        }
    }
  }
  .button-delete:hover{
    color:white !important;
  }
}