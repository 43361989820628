#installer-list-component{
    .outer-box{
        .MuiDataGrid-root{
            .grid-toolbar-cont{
                // float:right;
                button{
                   color:var(--color-2);
                }
            }
        }
        .MuiDataGrid-cell--textLeft{
            white-space:unset;
            .pause{
                font-family: ActualM;
                font-size: 14px;
                line-height: 15px;
                letter-spacing: 0.25px;
                color:#333;
            }
            .link-comment{
                font-family: ActualR;
                font-size: 14px;
                line-height: 15px;
                letter-spacing: 0.25px;
                color: #2266DD;
                margin-top:6px;
                cursor:pointer;
            }
        }
        .MuiStack-root{
            justify-content: flex-start !important;
            flex-wrap: wrap;
            margin-bottom: 10px;
            div{
                margin: 4px 0 0 4px;
            }
        }
        .MuiStack-root>:not(style)~:not(style) {
            margin-left : 0;
        }
        .MuiDataGrid-root .MuiDataGrid-cell--textLeft{
            justify-content: flex-start !important;
        }

    }
    .MuiPaginationItem-root.Mui-selected{
        background-color: var(--color-2);
    }
    .MuiDialogContent-root{
        .MuiFormControlLabel-root, label{
            span{
            color:#333333 !important;
            }
        }
    }
    .MuiFormControlLabel-root .MuiFormControlLabel-label, label{
        color:#333333 !important;
    }

    label {
        color: #333333 !important;
        font-weight: bold;
        font-size: 18px;
        margin-top:10px;
    }
    .product-type-chip{
        .MuiChip-root{
            border: 1px solid var(--color-2);
            color:var(--color-2);
        }
    }
    .installer-list-checkbox{
      .MuiFormControlLabel-root .MuiFormControlLabel-label { color: #333333;}
    }
    .installer-filter-section{
        div{
            margin-right: 10px;
        }
        button{
            background: var(--color-2)

        }
        button:hover{
            color:white;
        }
        button.Mui-disabled{
            color:white;
            background: var(--color-3);
        }
        .btn-mx-width {
            min-width: 150px;
            max-width: 300px;
            font-size: 14px;
        }
    }
    // .MuiDataGrid-virtualScroller{
    //     overflow: auto !important;
    //     height:auto !important;
    // }
    .MuiDataGrid-menuIcon{
        button{
            display: none;
        }
    }
    // .MuiDataGrid-root .MuiDataGrid-cell--textCenter[data-field = "actions"]{
    //     justify-content: flex-start;
    // }
    .reset-filter{
        margin-left:10px;
        color:blue;
        cursor:pointer;

    }

    // .MuiDataGrid-root .MuiDataGrid-cell--textCenter:last-child {
    //     -webkit-box-pack: center;
    //     -ms-flex-pack: center;
    //     -webkit-justify-content: center;
    //     justify-content: center;
    //     position: sticky;
    //     right: 0;
    //     background: white;
    //     z-index: 1;
    //     overflow: hidden;
    //     box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f;
    //     float:right;

    // }
    // .MuiDataGrid-pinnedColumns-1, .MuiDataGrid-columnHeader MuiDataGrid-columnHeader--alignCenter:last-child{
    //     -webkit-box-pack: center;
    //     -ms-flex-pack: center;
    //     -webkit-justify-content: center;
    //     justify-content: center;
    //     position: sticky;
    //     overflow: hidden;
    //     z-index: 1;
    //     box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f;
    //     background-color: #fff;
    //     right: 0;
    // }
}
#Product{
    
    box-sizing: content-box;
    /* background: wheat; */
    /* display: flex; */
    justify-content: center;
    text-align: center;
    align-items: center;
    background: white;
    padding: 24px;

    
        .display-time-input{
            width: 200px;
            border-radius: 3px;
        }
        .add-user-section{
            justify-content: flex-end;
        }
        .display-times-button-group{
            padding:17px 17px 0 17px;
        }
        #toggle{
            margin-top: 7px;
            padding: 5px;
            margin-bottom: 10px;
        }
        #switch{
            margin-left: 196px;
    margin-bottom: 12px;
        }
    
}
#channer_partner-mapping{
    background: white;
  .MuiOutlinedInput-root{
    width: 252px;
    height: 40px;
  }
 
  #text{
    margin-top: 26px;
    margin-right: 7px;
    margin-left: 30px;
  }
  .display-times-button-group{
    text-align: center !important;
    margin-top: 30px !important;

  }
  #copy_button{
    margin-top: 16px;
  }

  .copy-installer:hover{
    color:white;
  }

}

#pause_popup_section{
    .Mui-focused{
        color: #333;
    }
    .MuiFormLabel-root{
        font-family:ActualR;
        font-size:18px;
        line-height:20px;
        color:#333;
    }
    .MuiTypography-root.MuiTypography-body1{
        font-size:16px;
        line-height:15px;
        font-family:ActualR;
        color:#666;
    }
    .MuiTextField-root {
        margin-left:30px;
    .MuiFormLabel-root{
        font-family:ActualR;
        font-size:14px;
        line-height:20px;
        color:#aaa;
    }
    }
    .MuiInputBase-root.MuiFilledInput-root{
     background:white;
     border:1px solid #aaa;
     border-radius:4px;
    }
    .MuiInputBase-root.MuiFilledInput-root::before, .MuiInputBase-root.MuiFilledInput-root::after {
        border-bottom:none;
    }
}
.MuiPaper-root.MuiDialog-paper{
    width:100%;
}
.show_comment {
    font-family: ActualR;
    font-size: 16px;
    line-height: 20px;
    color: #333;
    margin-bottom:8px;
    .reason {
        font-family: ActualM;
    }
}

.comments {
    font-family: ActualM;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.5px;
    color: #333;
}

.additional_comment {
    font-family: ActualR;
    font-size: 14px;
    line-height: 17.5px;
    color: #666;
}
.MuiTypography-root.MuiDialogTitle-root{
    background: #333;
    font-family: "ActualR";
    font-weight: 400;
    font-size: 13px;
    color: #fff;
    margin: 0;
    font-family: "ActualR" !important;
}

