.breadcrumb{
    margin : 0;
    font-size:12px;
    .breadcrumb-item a{
        color:#66799e;
        text-decoration: none;
    }
    .breadcrumb-item a:hover, .breadcrumb-item.active a{
        color:#262b40;
        text-decoration: none;
    }
    a.breadcrumb-item.active{
        color:var(--color-2);
      }
    .breadcrumb-item.active a{
      cursor : none;
    }
    .breadcrumb-item+.breadcrumb-item::before {
        float: left;
        padding-right: 0.5rem;
        color:var(--color-2);
        content: var(--bs-breadcrumb-divider, ">");
    }
}