.user-management{
    margin-top:15px;
    .users-section{
        padding: 0 20px 20px 20px;
    }
    .MuiPaginationItem-root.Mui-selected , .MuiPaginationItem-root.Mui-selected:hover{
       background-color: var(--color-2);
    }
    label, .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label{
        color:#333 !important;
    }
    .MuiDataGrid-footerContainer{
        justify-content:flex-end;
    }
    .MuiPaper-root-MuiDialog-paper {
        min-width: 600px !important;
        min-height: 200px;
    }
    .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
        padding:6px !important;
        min-width:50px !important;
    }
    .avatar{
        width:40px; height:40px; border-radius:50%;
    }
    button:hover, .add-role:hover{
    color:white !important;
   }
}
    .add-role:hover{
    color:white !important;
   }
.add-user-section{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
}
i.icon-edit{
    color: var(--color-2);
}

i.icon-delete{
    color: red;
}
button.button-view, button.button-view:hover, span.button-view, span.button-view:hover{
    background-color: #ddd;
    color: black !important;
}

.create-role-section{
  color:black;
  div{  
   padding: 10px;
   color:black;
   .create-label{ 
    font-family: ActualR;
    font-size: 14px;
    color:black;
  }
}
}
.create-label-permission{
    font-family: ActualR;
    font-size: 14px;
    color:black;
}

.filter-search{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 800px;
    width: 100%;
    input{
     height:31px;
    }
}
#installer-history-logs-component-new_version{
   
        .outer-box{
          box-shadow: 0 4px 15px 0 rgba(102, 102, 102, 0.2);
          background-color: #fff;
          border-radius: 4px;
            .top-header{
            padding: 12px 16px;
            background-color: var(--color-1);
            color:white;
            border-radius: 4px 4px 0 0;
         }
         .history-log-section{
          padding: 0 20px 20px;
         }
        }
        .installer-dropdown{
          margin-top: 12px;
          margin-bottom: 12px;
        }
        .tab-active {
           color: white !important;
          background: var(--color-2);
        }
            
        .filtersSection{
          display: flex;
          align-items: center;
      
        }
        .filterLabel{
          font-family: actualM;
          font-size:13px;
          margin-bottom: -14px;
        }
      
        .filterContent{
          width:200px;
          margin: 10px 35px 0px 0px;
        }
        .searchText {
          border: 1px solid #aaa;
        //  padding: 0 0 0 4px;
          width: 100%;
          height: 35px;
          border-radius: 4px
        }
        .searchText:focus-visible {
       outline: none !important;
        }
      
        .titleSearch{
          margin: 10px 0px 22px 0px;
        }
        .moduleText{
          margin-bottom: 8px;
        }
        .TitleSearch{
          margin: 16px 0px 0px 28px;;
        }
        .titleSearchButton{
          font-family: ActualR;
          border: 1px solid #aaa;
          border-radius: 4px;
          color: white !important;
          background: var(--color-2);
        }
        .lowerFilterSection{
          display: flex;
          column-gap: 4px;
        }
        .dateRangeCont{
          display: flex;
          margin: -17px 0px 0px 0px;
          .react-datepicker-wrapper {
            display: block !important; 
          }
        }
        input.dateInput {
          border: 1px solid #aaa;
          border-radius: 4px;
          height: 34px;
          width:203px;
      }
        .input-container-range{
          border: 1px solid #aaa;
          border-radius: 4px;
          height: 30px;
        }
        .datesearch{
          border: 1px solid #aaa;
          border-radius: 4px;
          height: 34px;
          color: white;
          font-family: ActualR;
          background-color:  var(--color-2);
        }
        .history-record{
          margin: 5px 0px 0px 10px;
        }
        .action-perfomred{
          .dropdown .dropdown-menu {
            height: 125px;
            overflow: hidden scroll;
            font-size: 14px;
            width: 100%;
        }
        }
        .no-result {
          height: 60vh;
          border: 1px solid #aaa;
          display: flex;
          justify-content: center;
          align-items: center;
      
          p {
              font-family: actualM;
              font-size: 16px;
          }
      }
      .half-part{
        display: flex;
        flex-wrap:wrap;
      }
      .filterLabel_new{
        font-family: actualM;
        font-size: 13px;
      }
      .btn-marg {
        margin: 0px 0;
    }
    .second_part{
        display: flex;
    }
    .second_buttom{
        margin-top: 23px;
        margin-left: 14px;
    }
    .no-result {
      margin: 0px;
    }

    .table-cont{
      width:100%;
      min-width:400px;
      max-width: 550px;
      overflow:hidden;
      th, td{
        width:50%;
        word-break: break-all;
      }
    }
      

}
.table-cont{
  width:100%;
  min-width:400px;
  max-width: 550px;
  overflow:hidden;
  th, td{
    width:50%;
    word-break: break-all;
  }
}