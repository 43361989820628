.product-configurator{
    margin-top:10px;
    .configure{
        padding:0 0 20px 0;
    }
    .product-dnd, .product-footer, .channel-partner-configurator{
        padding: 0 20px;
    }
    .channel-partner-configurator{
    display:flex;
    justify-content:space-between;
    align-items:center;
    background-color: #fff;
    border-radius: 4px;
    font-family: ActualR, Roboto;
    padding: 0 20px;
    max-width:750px;
    h5{
        color:#333333;
        font-size:14px;
    }
    p{
        color:#666666;
        font-size:12px;
        text-align:left;
    }
    }
    .product-dnd{
        p.label{
            color: #333;
            font-size: 12px;
            font-family: 'ActualR';
            margin-bottom: 8px;
        }
    }
    .product-footer{
        // text-align: center;
        .product-configurator-button{
            width:250px;
        }
        .product-configurator-button:hover{
            color:white;
        }
    }
    .product-available-item{
        // max-width: 400px;
        // width: 100%;
        width: 300px;
        border: 1px solid #ced4da;
        background-color: white;
        padding: 8px;
        box-shadow: 2px 2px 2px #dcd9d9;
        color: #666666;
        .product-listing{
          text-transform: capitalize;
        }
    }
    .product-available-item:hover{
        color:black;
    }
    .product-icon::before{
     color: var(--color-1);
    }
    .saved-status{
        margin-left: 10px;
        color: #407d32;
        font-family: 'ActualB';
    }
}