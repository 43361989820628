#installer-dashboard-component{
    font-family:ActualR;
    .card-outer{
        width: 100%;
        max-width: 200px;
        background: #eda552;
        border-radius: 4px;
        margin-right:10px;
        cursor:pointer;
        // padding: 8px;
        i{
            background:#d37d27;
            width:30%;
            max-width:90px;
            padding-top: 12px;
            text-align: center;
        }
        i:before{
            font-size: 24px;
            color: white;
        }
        div.text{
            padding: 5px 10px;
            color: white;
            p{
                font-family:ActualB
            }
        }

    }

  
    // #63be5b,#53a049
    // #ed6389,#dd5460
    // #78cfd3,#51a7ab
    .card-outer1{
        margin-top:30px;
        width: 100%;
        max-width: 200px;
        background: #78cfd3;
        border-radius: 4px;
        margin-right:10px;
        cursor:pointer;

        // padding: 8px;
        .icon-box{
            background: #51a7ab;
            width: 50px;
            height: 50px;
            max-width: 90px;
            /* padding-top: 12px; */
            text-align: center;
            position: absolute;
            /* top: -20px; */
            margin-top: -10px;
            margin-left: 10px;
            border-radius: 0 100% 0 0;
        i{
            background:transparent;
            width:30%;
            max-width:90px;
            padding-top: 12px;
            text-align: center;
            position: absolute;
            top: 15%;
            left: 20%;
        }
        i:before{
            font-size: 24px;
            color: white;
        }
        }
        div.text{
            padding: 5px 10px;
            color: white;
            width: 100%;
            text-align: right;
            p{
                font-family:ActualB
            }
        }

    }

    .card-outer:nth-child(3), .card-outer1:nth-child(3){
        background: #63be5b;
        .icon-box , i{
            background: #53a049;
        }

    }
    .card-outer:nth-child(4), .card-outer1:nth-child(4){
        background: #ed6389;
        .icon-box, i{
            background: #dd5460;
        }

    }
    .card-outer:nth-child(2), .card-outer1:nth-child(2){
        background: #eda552;
        .icon-box, i{
            background: #d37d27;
        }

    }
    .card-outer:nth-child(1), .card-outer1:nth-child(1){
        background: #78cfd3;
        .icon-box, i{
            background: #51a7ab;
        }

    }
    // .MuiDataGrid-columnHeader:last-child,
    // .MuiDataGrid-columnHeadersInner.MuiDataGrid-columnHeadersInner--scrollable .MuiDataGrid-columnHeader:last-child
    // {
    //     position:absolute;
    //     left:0;
    //     overflow:hidden;
    //     z-index: 1;
    //     box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f;
    //     background-color: #fff;
    // }
    // .MuiDataGrid-pinnedColumns-1{
    // position: sticky;
    // overflow: hidden;
    // z-index: 1;
    // box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f;
    // background-color: #fff;
    // left: 0;
    // float: left;
    // }
}