.MuiButtonBase-root.button-var1{
    color:white;
    background: var(--color-1);
    // background: var(--color-1);
    text-transform: capitalize;
}
.MuiButtonBase-root.button-var1:hover{
    background: var(--color-1);
    color:white;
}
.MuiButtonBase-root.button-var2{
    color:white;
    background: var(--color-2);
    text-transform: unset;
}
.MuiButtonBase-root.button-var2:hover{
    background: var(--color-2);
    color:white;
}

.MuiButtonBase-root.button-var3{
    color: var(--color-4);
    border:1px solid;
    text-transform: capitalize;
}

.MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .MuiButtonBase-root-MuiCheckbox-root.Mui-indeterminate{
    color:var(--color-1);
}