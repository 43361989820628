#installer-approval-component{
    .button-g{
        margin-top:20px;
    }
    .MuiButtonGroup-root .MuiButtonGroup-grouped{
        font-family:ActualR;
        text-transform: capitalize;
        color: #101828;
        border: 1px solid var(--border-2);
        border-radius: 4px 4px 0 0;
    }
    .MuiButtonGroup-root .MuiButtonGroup-grouped{
       border: 1px solid #d1cccc;
    }
    .MuiButtonGroup-root .MuiButtonGroup-grouped.active {
        font-family:ActualM;
        text-transform: capitalize;
        background: var(--color-2);
        color:white;
        border: none;
    }
    .MuiButtonGroup-root .MuiButtonGroup-grouped:nth-child(1).active, .MuiChip-root.pending {
        background: #d37d27;
    }
    .MuiButtonGroup-root .MuiButtonGroup-grouped:nth-child(2).active, .MuiChip-root.approved {
        background: #53a049;
    }
    .MuiButtonGroup-root .MuiButtonGroup-grouped:nth-child(3).active, .MuiChip-root.rejected {
        background: #dd5460;
    }
    .MuiButtonGroup-root .MuiButtonGroup-grouped:nth-child(4).active, .MuiChip-root.verification {
        background: #51a7ab;
    }
    .MuiChip-root {
        padding:5px;
    }

}

#installer-approval-component{
    .outer-box{
        .MuiDataGrid-root{
            .grid-toolbar-cont{
                // float:right;
                button{
                   color:var(--color-2);
                }
            }
        }
    }
    .MuiPaginationItem-root.Mui-selected{
        background-color: var(--color-2);
    }
    .MuiDialogContent-root{
        .MuiFormControlLabel-root, label{
            span{
            color:#333333 !important;
            }
        }
    }
    .MuiFormControlLabel-root .MuiFormControlLabel-label, label{
        color:#333333 !important;
    }

    label {
        color: #333333 !important;
        font-weight: bold;
        font-size: 18px;
        margin-top:10px;
    }
    .product-type-chip{
        .MuiChip-root{
            border: 1px solid var(--color-2);
            color:var(--color-2);
        }
    }
    .installer-list-checkbox{
      .MuiFormControlLabel-root .MuiFormControlLabel-label { color: #333333;}
    }
    .installer-filter-section{
        div{
            margin-right: 10px;
        }
        button{
            background: var(--color-2)

        }
        button:hover{
            color:white;
        }
        button.Mui-disabled{
            color:white;
            background: var(--color-3);
        }
        .btn-mx-width {
            min-width: 150px;
            max-width: 300px;
            font-size: 14px;
        }
    }
    // .MuiDataGrid-virtualScroller{
    //     overflow: auto !important;
    //     height:auto !important;
    // }
    .MuiDataGrid-menuIcon{
        button{
            display: none;
        }
    }
    // .MuiDataGrid-root .MuiDataGrid-cell--textCenter[data-field = "actions"]{
    //     justify-content: flex-start;
    // }
    .reset-filter{
        margin-left:10px;
        color:blue;
        cursor:pointer;

    }
    button.button-var2:hover{
        color:white;
    }

    // .MuiDataGrid-root .MuiDataGrid-cell--textCenter:last-child {
    //     -webkit-box-pack: center;
    //     -ms-flex-pack: center;
    //     -webkit-justify-content: center;
    //     justify-content: center;
    //     position: sticky;
    //     right: 0;
    //     background: white;
    //     z-index: 1;
    //     overflow: hidden;
    //     box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f;
    //     float:right;

    // }
    // .MuiDataGrid-pinnedColumns-1, .MuiDataGrid-columnHeader MuiDataGrid-columnHeader--alignCenter:last-child{
    //     -webkit-box-pack: center;
    //     -ms-flex-pack: center;
    //     -webkit-justify-content: center;
    //     justify-content: center;
    //     position: sticky;
    //     overflow: hidden;
    //     z-index: 1;
    //     box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f;
    //     background-color: #fff;
    //     right: 0;
    // }
    
}

.no-result {
    height: 250px;
    width: 100%;
    margin: 20px;
    // border: 1px solid #aaa;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // background-color: #fff;
    p {
        font-family: actualM;
        font-size: 16px;
    }
    .back-projects{
        color: #007bff;
        text-decoration: underline;
        margin:16px 0 0 0;
        cursor: pointer;
    }
}
.installer-file-list{
    .searchText {
        border: 1px solid #aaa;
        padding: 0 0 0 4px;
        width: 20%;
        height: 33px;
        border-radius: 4px;
        margin-top: 8px;
    }
    .outer-box{
        .MuiDataGrid-root{
            .grid-toolbar-cont{
                 float:left;
                button{
                   color:var(--color-2);
                }
            }
        }
    }
}
.approvalSysytemfileNav{
    .fileUpload-image {
        display: flex;
        align-items: center;
        justify-content: center;
        .uploadedImage {
            width: 550px;
            height: 450px;
            border: 1px solid #aaaa;
            margin: auto;
            padding: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            .display-imagefile {
             height: 100%;
             display: flex;
             align-items: center;
                img {   
                    width: 100%;
                    height: 100%;
                }   
    
            }
    
        }
    }
    .downloadItems {
        display: flex;
        align-items: center;
        flex-direction: column;
        button {
            border: 2px solid #2E7D32;
            height: 32px;
            color: #2E7D32;
            background-color: white;
            font-size: 12px;
        }
    }
}

.installer-additional-user-search{
    padding: 20px 0 0 20px;
    input{
    padding: 10px 10px 10px 30px;
    border-radius: 25px;
    width: 300px;
    }
    .icon-search1 {
        position: absolute;
        margin: 15px 0 0 15px;
    
    }
}

