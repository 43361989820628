.downtime-radio,.downtime-checkbox{
  padding: 0 20px;
    .input-container{
      margin-bottom:15px;
      .update-link{
        color: #1976d2;
        font-family: 'ActualM';
        margin-left: 10px;
        font-size: 11px;
        cursor: pointer;
      }
      input{
          width: 85%;
          padding: 0.375rem 0.75rem;
          font-size: 14px;
          color: #212529;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          -webkit-appearance: none;
          appearance: none;
          border-radius: 0.25rem;
          transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          font-family: 'ActualR'
      }
    }
    .form-control{
      width: 320px;
      max-width: 80%;
    }

    label{
        color:#333333;
        font-family: ActualR;
        font-size: 14px;
        margin: 0;
        font-weight:normal;
        margin-bottom:5px;
        span{
            font-family: 'ActualR';
            font-size: 14px;
        }
    }
    label + & {
        margin-top: 3px;
      }
    .MuiInputBase-input{
        border-radius: 4;
        position: 'relative';
        // border: 1px solid #ced4da;
        font-size: 14px;
        padding: '10px 26px 10px 12px';
        font-family: ActualR;
        &:focus {
          border-radius: 4;
          border-color: '#80bdff';
          box-shadow: '0 0 0 0.2rem rgba(0,123,255,.25)';
        }
      }
      #demo-customized-select{
        padding: 5px 32px 5px 5px;
        border: 1px solid #cbcbcb;
      }
      .MuiInputBase-input{
        padding:5px;
      }
      .react-datepicker-wrapper{
        max-width: 250px;
      }

      .schedule-picker {
        display:flex;
        align-items:center;
        justify-content:flex-start;
        max-width:250px;
          .date-pick{
            margin-right:30px;
            align-items: center;
            input{
              width:250px;
              border: 1px solid #cbcbcb;
              border-radius: 4px;
            height: 35px;
            font-family: 'ActualR';
            }
            input.react-datepicker-ignore-onclickoutside{
              border-color: var(--bs-blue);
            }
            .MuiInputBase-root , select{
              width:70px;
              margin-right:15px;
            }
        }
      }

}

.downtime-checkbox{
  padding: 0;
  .css-1je6oy9{
  flex-wrap:wrap;
  div{
    flex: 0 0 100%;
    label{
      width: 40%;
    }
    input{
      margin-left:15px;
    }
  }
}
}
.marketplace-selection{
  height: calc(100vh - 216px) !important;
  width: 550px !important;
}
input{
  font-size:14px;
  font-family: 'ActualR';
  padding: 0 10px;
}

.downtime-footer{
  padding: 0 20px 20px 20px;
  text-align: center;
  button{
    width:280px;
    margin:0 !important;
    text-transform: capitalize;
  }
}

.review-details{
  padding:20px;
  p{
    text-align:center;
  }
}
.review-details.configure-tab, .review-details.tab{
  padding: 0 20px 20px 20px;
  .MuiTypography-root{
    width:100%;
    .accordion-header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
  .btn-preview{
    margin: 0 8px;
    span{
      margin-right:5px;
    }
  }
  .btn-preview:hover{
    background:white;
    color:#333333;
  }
  .btn-update{
    width:200px;
    margin-right:20px;
  }
  .btn-update:hover{
    color:white;
  }
}
.review-details.tab{
  padding: 0 20px;
}

.border-red{
  border:1px solid red;
}
 .accordion-data.MuiPaper-root{
  border: 2px solid black;
  margin: 16px 0;
  border-radius: 4px !important;
  .MuiButtonBase-root{
    min-height: 40px;
    padding: 0;
    margin-right: 6px;
    .MuiAccordionSummary-content{
      margin: 10px 0;
      padding: 0 16px;
      .Mui-expanded{
        min-height:40px;
        border-bottom: 2px solid #c3c3c3;
      }
    }
  }
  .MuiAccordionSummary-content.Mui-expanded{
    min-height:40px;
    border-bottom: 2px solid #c3c3c3;
  }
}
.MuiPaper-root-MuiAccordion-root:first-of-type{
  margin-top:0;
}
.MuiAccordionDetails-root{
  padding:8px 0 !important;
}


.MuiTabs-scroller{
  margin-right: 0px;
  background: #eee;
  padding-top: 22px;
  .MuiButtonBase-root-MuiTab-root{
    color: black;
    border: 2px solid black;
    border-radius: 4px;
    text-transform: capitalize;
    font-family: 'ActualR';
    font-size: 14px;
    min-height: 20px;
  }
  .MuiButtonBase-root-MuiTab-root.Mui-selected{
    background:white;
    color:black;
    font-family:'ActualR'
  }
}
.editor-content-show{
  border: 1px solid #ced4da;
  text-align: left;
  width: 95%;
  max-width:800px;
  padding: 10px;
  border-radius: 4px;
  p{
    text-align: left;
  }
  img{
    width:100%;
  }
}

.configured-details{
  border: 1px solid #ced4da;
  width: 100%;
  padding-bottom: 20px;
 .downtime-radio{padding:0}
  .accordion-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'ActualM';
    border-bottom: 1px solid #ced4da;
    padding:0 20px;
  }
  p.not-available{
    padding-top:20px;
  }
}

.downtime-footer{
  .back-btn{
    position: absolute;
    margin-top: 8px;
    color: #1976d2;
    cursor:pointer;
  }
}
.select-marketplace-link{
  a{
    cursor:pointer;
  }
}

.electrum-font:before, .CC-icon:before{
  font-size:12px;
}
.CC-icon:before{
  color: #48bc25;
  margin-left:10px;
}


//review
.uploaded-image-review{
  width:155px;border:1px solid #333;
}
.channel-partner{
  padding:10px 20px;
}

.channel-partner1{
  p{text-align:left;}
}
