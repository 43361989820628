// This is css is going to be used in tools -> file Uploader
#fileUploader {
    .outer-box{
        padding-bottom: 10px;
        .upload-section {
            padding: 24px;
            .form-section {
                padding: 16px;
                border: 1px solid #c6c2c2;
                border-radius: 4px;
            }
            form {
                .formGroup{
                    display: flex;
                    flex-direction: column;
                    width: 30%;
                    padding-bottom: 16px;
                }
                label {
                    padding-bottom: 8px;
                }
                input {
                    border: 1px solid;
                    padding: 8px;
                    border-radius: 4px;
                    margin-right: 16px;            
                }
                .UploadBtn {
                    margin-top: 8px;
                    padding: 4px 8px;
                }
            }
            .res-section {
                padding: 16px 0;
                .resDisplay {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    span {
                        margin-right: 16px;
                        font-family: actualM;
                    }
                    .icon {
                        font-size: 16px;
                        border: 1px solid #d6d6d6;
                        padding: 5px;
                        border-radius: 7px;
                        &:hover {
                            background-color: #f1f1f1;
                        }
                    }
                }
            }
            .text {
                padding: 24px 10px 0 0px;
                color: #333;
                font-size: 12px;
                font-family: 'ActualR';
                margin-bottom: 8px;
            }
        }
    }
}