*,
*::after,
*::before {
    box-sizing: inherit;
}

body {
    background: #eee;
    font-size: 14px;
    overflow: auto;
    height: 100%;
    margin: 0;
    font-family: ActualR, Roboto;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin: 0;
        padding: 0;
        letter-spacing: 0;
        font-family: ActualR, Roboto;
    }

    h1.control-bold,
    h2.control-bold,
    h3.control-bold,
    h4.control-bold,
    h5.control-bold,
    h6.control-bold,
    p.control-bold {
        font-family: ActualM, Roboto;
    }

    h1 {
        font-size: 32px;
    }

    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 24px;
    }

    h4 {
        font-size: 20px;
    }

    h5 {
        font-size: 18px;
    }

    h6 {
        font-size: 16px;
    }

    .control-sm {
        font-size: 12px;
    }

    .control-md {
        font-size: 14px;
    }

    .control-lg {
        font-size: 16px;
    }

    button {
        font-family: ActualM, Roboto;
        font-size: 14px;
        text-transform: capitalize;
        letter-spacing: 1.25px;
    }

    .hint {
        font-family: ActualR, Roboto;
        font-size: 10px;
        letter-spacing: 1.5px;
    }

    a {
        font-family: ActualR, Roboto;
        // font-size: 10px;
        text-decoration: none;
        // letter-spacing: 1.5px;
    }
    p{
        margin-bottom: 0;
    }
}

.react-datepicker-popper{
    z-index:9 !important;
  }

.btn-layout:hover {
    color:white !important;
}

.green-1{
    background :#48bc25;
    color:white;
}
.MuiFormGroup-root, .MuiBox-root, .MuiFormControlLabel-root{
    font-family: ActualR;
    .MuiRadio-root.Mui-checked, .MuiCheckbox-root.Mui-checked, 
    .MuiCheckbox-root.Mui-checked, .MuiCheckbox-root.MuiCheckbox-indeterminate,
    .MuiCheckbox-root{
        color: var(--color-2) !important;
    }
}
span.MuiButtonBase-root-MuiRadio-root.Mui-checked, span.MuiButtonBase-root-MuiRadio-root{
    color: var(--color-2) !important;
}
.react-datepicker__day--selected, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{
    background-color: var(--color-2) !important;
}

.checkboxTree-component.tree-view{
 .rct-checkbox, .rct-node-icon{
     display:none !important;
 }
}
