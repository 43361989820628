.checkboxTree-component{
.rct-title {
    padding: 0 5px;
    color: black;
    font-family: ActualR;
    font-size: 14px;
    font-weight:normal;
}
.checkboxTree-icon, .checkboxTree-icon1{
    font-family:12px;
}
.checkboxTree-icon::before{
    color: var(--color-2);
}
.checkboxTree-icon1::before{
    color: #666;
}
label{
    margin-top: 0;
}
.react-checkbox-tree label:hover {
    background: white;
}
}