.user-configurator {
    .user-configuration-section {
        background: white;
    }

    .user-profile-container {
        .profile-section {
        padding: 20px 20px 1px;
        }

        .header-container {
            display: flex;
            justify-content: space-between;

            // .buttons{
            //     margin-bottom: 8px;
            // }
            .status {
                margin-top: 14px;
                position: absolute;

                p {
                    margin-bottom: 6px;
                }
            }

            .deletestatus {
                margin-top: 14px;
                position: absolute;
                right: 38px;

                p {
                    margin-bottom: 6px;
                }
            }

            h3 {
                font-size: 18px;
                font-family: 'ActualMedium';
            }

            button {
                height: 35px;
                min-width: 92px;
                font-family: 'ActualR';
                border-radius: 4px;
                font-size: 14px;
                border: none;
                cursor: pointer;
                color: #fff;
                letter-spacing: 0;

            }

            .edit-button {
                background: #0288D1;
            }

            .delete-button {
                background: #D32F2F;
                margin-left: 24px;
            }

            .restore-button {
                background: #2E7D32;
                margin-left: 24px;
            }
        }

        .input-cont {
            display: flex;
            margin-bottom: 20px;
            font-family: 'ActualR';
            // max-width: 500px;
            // width: 100%;

            .label-head{
                font-family:ActualB;
                font-size:16px;
            }

            input {
                padding: 1px 8px 5px;
                width: 280px;
                font-family: 'ActualR';
                font-size: 14px;
                // width: 100%;
            }

            .input,
            .profile-intro {
                p {
                    color: #DD2200;
                    margin-left: 0;
                    max-width: 258px;
                }

                legend {
                    display: none;
                }
            }

            .view-input {
                fieldset {
                    background-color: #e9ecef;
                }
            }

            .dropdown {
                max-width: 310px;
                width: 100%;

                // .MuiFormControl-root {}

                .MuiSelect-select {
                    padding: 1px 8px 5px;
                }

                legend {
                    display: none;
                }
            }

            #demo-simple-select-label {
                top: -15px;
                left: -5px;
                color: #aaa;
            }

            .profile-intro {
                legend {
                    display: none;
                }

                div {
                    div {
                        padding: 0;
                    }
                }

                textarea {
                    width: 285px;
                    margin-left: 8px;
                }
            }

            .MuiInputBase-root.MuiOutlinedInput-root {
                padding: 0 8px;
                font-family: ActualR;
                font-size: 14px;
            }

            .upload-container {
                .upload_button {
                    background-color: #75d;
                    color: #fff;
                    border: none;
                    // .icon-camera1:before {
                    //     content: "\f030";
                    // }
                }
            }

            .image {
                max-width: 310px;
                max-height: 175px;
            }

            .input-inner {
                display: flex;
                min-width: 150px;
                align-items: center;
                margin-right: 64px;



                .MuiSelect-select {
                    padding: 10px;
                    background-color: #DD2200;
                }

                span {
                    margin-right: 6px;
                    font-family: 'ActualM';
                    font-size: 16px;
                }

                .icon-info-circle:before {
                    content: "\f05a";
                }
            }
        }

        .button-container {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
            align-items: center;

            .cancel,
            .submit {
                height: 40px;
                min-width: 150px;
                font-family: 'ActualM';
                border-radius: 4px;
                border: 1px solid #666;
                font-size: 14px;
                background: #fff;
                letter-spacing: 0;
                margin: 0 10px;
            }

            .submit-error {
                .submit {
                    height: 42px;
                    background: #48bc25;
                    color: #fff;
                    border: none;
                }

                p {
                    color: #DD2200;
                }
            }
        }
    }

    .button-group-tab {
        border-bottom: 1px solid #dddddd;
        width: 100%;
        border-radius: 0;
        padding: 15px 0 0;
    }

    .MuiButtonGroup-root .MuiButtonGroup-grouped {
        color: var(--color-3);
        font-family: ActualM;
        text-transform: capitalize;
        border: 1px solid #ddd;
        border-radius: 0 16px 0 0;
    }

    .tab-active, .tab-active:hover {
        color: white !important;
        background: var(--color-2);
    }
}

.configure {
    .inner-container {
        font-family: ActualR;
        padding: 24px;

        .error {
            color: #DD2200;
            margin: 8px 0 0 40px;
            font-size: 13px
        }
        .error-box{
            margin: 0 0 0 16px;
        }

        .input {
            legend {
                display: none;
            }

        }

        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .heading-container {
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;

            .title {
                h3 {
                    font-family: ActualM;
                    font-size: 18px;
                    margin-bottom: 8px;
                }
            }

            button {
                height: 35px;
                min-width: 92px;
                font-family: 'ActualR';
                border-radius: 4px;
                font-size: 14px;
                border: none;
                cursor: pointer;
                color: #fff;
                letter-spacing: 0;

            }

            .edit-button {
                background: #0288D1;
            }
        }

        .flags-container {
            display: flex;
            margin-bottom: 40px;

            .label-cont {
                display: flex;
                margin-right: 48px;
                align-items: center;

                .lable-icon {
                    display: flex;
                    align-items: center;
                    margin-right: 32px;

                    p {
                        margin-right: 6px;
                    }
                }
            }
        }

        .current-cap-cont {
            margin-bottom: 14px;

            .cap-cont {
                display: flex;
                width: 100%;
                justify-content: flex-start;
                max-width: 500px;;
                .cap-inner {
                    display: flex;
                    margin: 0 0 16px 0;   
                    flex: 0 0 50%; 

                    input {
                        padding: 1px 8px 5px;
                        width: 32px;
                        font-family: 'ActualR';
                        font-size: 14px;
                        text-align: center;
                        // width: 100%;
                    }

                    .cap {
                        display: flex;
                        align-items: center;
                        width: 155px;
                        margin-right: 32px;

                        p {
                            margin-right: 6px;
                        }
                    }
                }
                // p.current{
                //     // margin-left:60px;

                // }
            }
        }

        .event-grid {
            margin-bottom: 40px;

            input {
                padding: 1px 8px 5px;
                font-family: 'ActualR';
                font-size: 14px;
                
            }
            .phone {
                padding: 5px 8px 1px;
                font-family: 'ActualR';
                font-size: 14px;
                border: 0.8px solid #cfc9c9;
                border-radius: 6px;
            }

            .phone::after{
                border: none !important;
            }
            .phone::before{
                border: none !important;
            }

            input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    margin: 0; 
                    display: none;
                }
            

            .heading-cont {
                display: flex;
                align-items: center;

                p {
                    margin-right: 6px;
                    font-family: actualM;
                }
            }

            .radio-button {
                color: #aaa;
            }

            .radio-button-edit {
                color: #75d;
            }

            .MuiDataGrid-root {
                // max-width: 250px;
            }

            .MuiDataGrid-main {
                background: #fff;

                div:nth-of-type(2) {
                    height: auto !important;
                }

                .MuiDataGrid-columnHeaderTitle {
                    font-family: actualM;
                }
            }

            .MuiDataGrid-virtualScroller {
                overflow: auto !important;
                height: auto !important;
            }

            .MuiDataGrid-menuIcon{
                button{
                    display: none;
                }
            }

            .Mui-selected {
                background-color: var(--color-2) !important;
            }

            .MuiDataGrid-row.Mui-selected {
                background-color: rgba(25, 118, 210, 0.08) !important;
            }

            .MuiDataGrid-toolbarContainer {
                justify-content: flex-end;

                button {
                    color: var(--color-2);
                }
            }

            .MuiDataGrid-cell:focus {
                outline: none !important;
            }

            .states {
                max-width: 200px;
                overflow: auto;
            }

            ::-webkit-scrollbar {
                width: 1px;
                // background-color: red;
                height: 10px;
                max-height: 50%;
                height: 50%;
            }

            ::-webkit-scrollbar-track {
                background: transparent;
            }

            ::-webkit-scrollbar-thumb {
                background: #333
            }
        }

        .button-container {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
            align-items: center;
            flex-direction: column;

            hr {
                width: 100%;
                margin: 8px 0 40px 0;
            }

            .cancel,
            .submit {
                height: 40px;
                min-width: 150px;
                font-family: 'ActualM';
                border-radius: 4px;
                border: 1px solid #666;
                font-size: 14px;
                background: #fff;
                letter-spacing: 0;
                margin: 0 40px;
            }

            .submit-error {
                .submit {
                    height: 42px;
                    background: #48bc25;
                    color: #fff;
                    border: none;
                    max-width: 150px;
                }
            }
        }
    }
}
.MuiDataGrid-menuIcon{
    button{
        display: none;
    }
}