#global-settings-component {
    // background: white;
    font-family: ActualR;
    border-radius: 4px;

    .add-user-section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        align-items: center;
    }

    .button-group-tab {
        border-bottom: 1px solid #dddddd;
        width: 100%;
        border-radius: 0;
        padding: 15px 0 0;
    }

    .MuiButtonGroup-root .MuiButtonGroup-grouped {
        color: var(--color-3);
        font-family: ActualM;
        text-transform: capitalize;
        border: 1px solid #ddd;
        border-radius: 0 16px 0 0;
    }

    .settings-tab {
        padding: 10px 15px 15px;
        background: white;


        p.heading {
            font-family: ActualM;
            font-size: 15px;
        }

        p.filter-label {
            font-family: ActualM;
            font-size: 13px;
        }
    }

    .display-time-input {
        width: 50px;
        text-align: center;
        padding: 0 2px;
    }

    .display-times-button-group {
        font-family: ActualR;
        margin-top: 20px;
        text-align: center;

        button {
            margin: 0 10px;
        }

        button:first-child {
            background-color: #ddd;
            color: black;
        }

        button:last-child:hover {
            color: white;
        }

    }

    // .outer-box{
    .MuiDataGrid-root {
        .grid-toolbar-cont {
            float: right;

            button {
                color: var(--color-2);
            }
        }
    }

    // }
    .MuiPaginationItem-root.Mui-selected {
        background-color: var(--color-2);
    }

    .MuiDialogContent-root {

        .MuiFormControlLabel-root,
        label {
            span {
                color: #333333 !important;
            }
        }
    }

    .MuiFormControlLabel-root .MuiFormControlLabel-label,
    label {
        color: #333333 !important;
    }

    label {
        color: #333333 !important;
        font-weight: bold;
        font-size: 18px;
        margin-top: 10px;
    }

    .product-type-chip {
        .MuiChip-root {
            border: 1px solid var(--color-2);
            color: var(--color-2);
        }
    }

    .installer-list-checkbox {
        .MuiFormControlLabel-root .MuiFormControlLabel-label {
            color: #333333;
        }
    }

    .installer-filter-section {
        div {
            margin-right: 10px;
        }

        button {
            background: var(--color-2)
        }

        button:hover {
            color: white;
        }

        button.Mui-disabled {
            color: white;
            background: var(--color-3);
        }

        .btn-mx-width {
            min-width: 150px;
            max-width: 300px;
            font-size: 14px;
        }
    }

    .MuiDataGrid-virtualScroller {
        overflow: auto !important;
        height: auto !important;
    }

    .MuiDataGrid-menuIcon {
        button {
            display: none;
        }
    }

    // .MuiDataGrid-root .MuiDataGrid-cell--textCenter[data-field = "actions"]{
    //     justify-content: flex-start;
    // }
    .reset-filter {
        margin-left: 10px;
        color: blue;
        cursor: pointer;

    }

    // .MuiDataGrid-root .MuiDataGrid-cell--textCenter:last-child {
    //     -webkit-box-pack: center;
    //     -ms-flex-pack: center;
    //     -webkit-justify-content: center;
    //     justify-content: center;
    //     position: sticky;
    //     right: 0;
    //     background: white;
    //     z-index: 1;
    //     overflow: hidden;
    //     box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f;
    //     float:right;

    // }
    // .MuiDataGrid-pinnedColumns-1, .MuiDataGrid-columnHeader MuiDataGrid-columnHeader--alignCenter:last-child{
    //     -webkit-box-pack: center;
    //     -ms-flex-pack: center;
    //     -webkit-justify-content: center;
    //     justify-content: center;
    //     position: sticky;
    //     overflow: hidden;
    //     z-index: 1;
    //     box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f;
    //     background-color: #fff;
    //     right: 0;
    // }
    button:hover {
        color: white;
    }

    .MuiButtonBase-root.MuiCheckbox-root.Mui-checked,
    .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-indeterminate,
    .MuiButtonBase-root.MuiRadio-root.Mui-checked {
        color: var(--color-2);
    }

    .MuiButtonBase-root.MuiCheckbox-root.Mui-disabled{
       color:#666;
    }

    .region-button-group {
        button {
            margin-left: 10px;
        }
    }

    .tab-active {
        color: white !important;
        background: var(--color-2);
    }

    .MuiDataGrid-columnHeaderTitle {
        font-family: ActualM;
        font-weight: none;
    }

    // ------------USER ROLES----------------

    .user-roles {
        .header-cont {
            display: flex;
            justify-content: space-between;
            // max-width: 750px;

            h3 {
                font-family: ActualM;
                font-size: 20px;
                margin: 8px 0 16px 0;
            }

            button {
                height: 35px;
                min-width: 100px;
                font-family: 'ActualR';
                border-radius: 4px;
                font-size: 14px;
                border: none;
                cursor: pointer;
                color: #fff;
                letter-spacing: 0;
                background-color: #2e7d32;

            }
        }

        .input-cont {
            background-color: red;
            display: flex;
            align-items: baseline;

            span {
                margin-right: 16px;
                font-size: 16px;
                font-family: 'ActualM';
                color: #333;
            }

            input {
                padding: 8px;
            }

            p {
                margin: 0;
            }
        }

        table {
            width: 100%;
            max-width: 250px;
            font-family: ActualR;
            border: 1px solid #aaa;

            tr {
                height: 52px;
                border-bottom: 1px solid #aaa;
                text-align: left;
                vertical-align: middle;

                th,
                td {
                    padding: 16px;
                }
            }

            .scroll {
                overflow-y: scroll;
                max-height: 50vh;

            }
            ::-webkit-scrollbar {
                width: 1px;
                // background-color: red;
                height: 10px;
                max-height: 50%; height: 50%;
              }
              ::-webkit-scrollbar-track {
                background: transparent;
            }
            ::-webkit-scrollbar-thumb {
                background:#333
            }
            table{
                border:none;
            }
        }

        // .MuiDataGrid-root {
        //     max-width: 250px;
        // }

        // .MuiDataGrid-main {
        //     background: #fff;

        //     div:nth-of-type(2) {
        //         height: auto !important;
        //     }

        //     .MuiDataGrid-columnHeaderTitle {
        //         font-family: actualM;
        //     }
        // }

        // .MuiDataGrid-virtualScroller {
        //     overflow: auto !important;
        //     height: auto !important;
        // }

        // .Mui-selected {
        //     background-color: var(--color-2) !important;
        // }

        // .MuiDataGrid-row.Mui-selected {
        //     background-color: rgba(25, 118, 210, 0.08) !important;
        // }

        // .MuiDataGrid-toolbarContainer {
        //     justify-content: flex-end;

        //     button {
        //         color: var(--color-2);
        //     }
        // }

        // .MuiDataGrid-cell:focus {
        //     outline: none !important;
        // }
    }

    .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
        padding: 0 2px;
    }
    button.button-view, span.button-view{
        background-color: #ddd;
        color: black;
    }

    .filter-section{
        align-items: end;
        flex-wrap:wrap;
        margin-bottom:15px;
        font-family: ActualR;
        font-size: 14px;
        .MuiFormControl-root{
            margin:10px 15px 0 0;
        }
        .MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiOutlinedInput-input.MuiInputBase-input{
            padding:7px;
            font-family: ActualR;
            font-size: 14px;
        }
        .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input{
            padding: 6px;
            min-width:50px;
            font-family: ActualR;
            font-size: 14px;
        }
        .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
            legend{
                display:none;
            }
        }
        .MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate,
        .MuiCheckbox-root.MuiButtonBase-root.Mui-checked{
            color:var(--color-2);
        }
    }
    .MuiButtonBase-root-MuiMenuItem-root .MuiListItemText-root{
        .MuiTypography-root{
        font-family: ActualR;
        font-size: 14px;
        }
    }
    .MuiList-root.MuiList-padding.MuiMenu-list{
        .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root{
            padding: 0;
            font-family: ActualR;
            font-size: 14px;
        }
    }
}
.MuiButtonBase-root-MuiMenuItem-root .MuiListItemText-root{
    .MuiTypography-root{
    font-family: ActualR;
    font-size: 14px;
    }
}
.MuiList-root.MuiList-padding.MuiMenu-list{
    .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root{
        padding: 0;
        font-family: ActualR;
        font-size: 14px;
    }
}

.MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate,
.MuiCheckbox-root.MuiButtonBase-root.Mui-checked{
    color:var(--color-2);
}

.red-cell{
    // background: #dd6767 !important;
    // color:white;
    background:#f9cfcf;
}
.save-floating-button{
    position: fixed;
    background: #fff;
    bottom: 0;
    display: flex;
    width: 100vw;
    justify-content: center;
    padding: 10px;
    z-index:9999;
    left: 0;
}
.p-rel{
    position: relative;
    width:100%;
    padding:0;
}
#Blocker-logic{
    .display-time-input{
        width: 200px;
        border-radius: 3px;
    }
    .add-user-section{
        justify-content: flex-end;
    }
}