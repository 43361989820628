// components
@import './components/dropdown.scss';
@import './components/dialog.scss';
@import './components/tinyEditor.scss';


//Dashboard 
@import './dashboard/installer.scss';
@import './dashboard/maintenance/downtime.scss';
@import './dashboard/maintenance/preview.scss';
@import './dashboard/homeDashboard.scss';
@import './dashboard/tools/productConfiguration/configurator.scss';
@import './dashboard/tools/partnerUtilityConfiguration/utilityAPIConfigurator.scss';
@import './dashboard/tools/projectListConfigurator/projectList.scss';
@import './dashboard/users/userList.scss';
@import './dashboard/installer/installerListing.scss';
@import './dashboard/installer/installer.scss';
@import './dashboard/installer/approvalSystem.scss';
@import './dashboard/installer/historyLogs.scss';
@import './dashboard/scheduler/staffConfig.scss';
@import './dashboard/scheduler/globalSettings.scss';
@import './dashboard/scheduler/staffBulkEdit.scss';
@import './dashboard/scheduler/userConfigurator/userConfigurator.scss';
@import './dashboard/scheduler/analysis.scss';
@import './dashboard/tools/productEditor.scss';
@import './dashboard/tools/errorLog/errorList.scss';
@import './dashboard/tools/fileUploader/fileUploader.scss';
@import './dashboard/product/productEditor.scss';
@import './dashboard/product/manufacturer.scss';
@import './dashboard/projectList/projectDetail.scss';
@import './dashboard/contracts/templateEditor.scss';
@import './dashboard/contracts/templateList.scss';


//login
@import './dashboard/account/login.scss';

.filter-section{
    align-items: end;
    flex-wrap:wrap;
    margin-bottom:15px;
    font-family: ActualR;
    font-size: 14px;
    .MuiFormControl-root{
        margin:10px 15px 0 0;
    }
    .MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiOutlinedInput-input.MuiInputBase-input{
        padding:7px;
        font-family: ActualR;
        font-size: 14px;
    }
    .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input{
        padding: 6px;
        min-width:50px;
        font-family: ActualR;
        font-size: 14px;
    }
    .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
        legend{
            display:none;
        }
    }
    .MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate,
    .MuiCheckbox-root.MuiButtonBase-root.Mui-checked{
        color:var(--color-2);
    }
}
.MuiButtonBase-root-MuiMenuItem-root .MuiListItemText-root{
    .MuiTypography-root{
    font-family: ActualR;
    font-size: 14px;
    }
}
.MuiList-root.MuiList-padding.MuiMenu-list{
    .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root{
        padding: 0;
        font-family: ActualR;
        font-size: 14px;
    }
}
.MuiButtonBase-root-MuiMenuItem-root .MuiListItemText-root{
.MuiTypography-root{
font-family: ActualR;
font-size: 14px;
}
}
.MuiList-root.MuiList-padding.MuiMenu-list{
.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root{
    padding: 0;
    font-family: ActualR;
    font-size: 14px;
}
}

.MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate,
.MuiCheckbox-root.MuiButtonBase-root.Mui-checked{
color:var(--color-2);
}