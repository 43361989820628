.product-editor-component{
    margin-top:20px;
    .button-editor:hover{
        color:white;
    }
}

    #editinstaller-component {
        .save {
            height: 40px;
            min-width: 100px;
            font-family: 'ActualM';
            border-radius: 4px;
            border: none;
            color: #fff;
            font-size: 14px;
            background: #48BC25;
            letter-spacing: 0;
            margin: 0 10px;
        }
        .button-view_cancel{
            height: 40px;
            min-width: 150px;
            font-family: 'ActualM';
            border-radius: 4px;
            border: none;
            color:black;
            font-size: 14px;
            background: #ddd;
            letter-spacing: 0;
            margin: 0 10px;
        }
        .upload{
            display: flex;
        }
        .image_shown{
            height: 45px;
            width: 100px;
            .image{
                width: 100%;
                height: 60px;
              
            }
        }
        background: #fff;
        .mb-1{
            font-family: 'ActualMedium';
        }
    #panel1bh-header {
        background-color: var(--color-1);
        color: #fff;
        margin-top: 20px;
        margin-left: 0px;
    }
 .MuiOutlinedInput-input {
        padding: 2px;
    }

    .input-cont {
        display: flex;
        margin-bottom: 20px;
        font-family: 'ActualR';
        margin-top: 10px;
    }

    .input-inner {
        display: flex;
        align-items: center;
        margin-right: 64px;
        min-width: 200px;
    }
    .btn-mx-width {
        min-width: 269px;
        max-width: 300px;
        font-size: 14px;
        margin: 0px 0px 0px 0px !important;
       
    }

    .input-inner span {
        margin-right: 6px;
        font-family: 'ActualM';
        font-size: 16px;
    }

    .css-173wfxe {
        display: none;
    }

    .MuiOutlinedInput-root .MuiInputBase-root .MuiInputBase-colorPrimary .MuiInputBase-formControl .MuiInputBase-root-MuiOutlinedInput-root {
        padding: 0 8px;
        font-family: ActualR;
        font-size: 14px;
    }

    .MuiOutlinedInput-input .MuiInputBase-input .MuiInputBase-input-MuiOutlinedInput-input {
        padding: 1px 8px 5px;
        width: 280px;
        font-family: 'ActualR';
        font-size: 14px;
    }

    .input-cont input {
        padding:8px;
        width: 280px;
        font-family: 'ActualR';
        font-size: 14px;
        border: 1px solid green($color: #000000);
    }

    // .input-cont {
    //     padding: 0 20px;
    // }

    .-MuiPaper-root-MuiAccordion-root.Mui-expanded:first-of-type {
        margin-top: 0;
    }

    #t {
        height: 100px;
    }

    .css-54wpc9 {
        height: 60px;
    }

    .MuiFormControl-root .MuiFormControl-root {
        height: 0;
    }

    .profile-section {
        padding: 24px 24px;
    }

    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cancel {
        height: 40px;
        min-width: 150px;
        font-family: 'ActualM';
        border-radius: 4px;
        border: 1px solid #666;
        font-size: 14px;
        background: #fff;
        letter-spacing: 0;
        margin: 0 10px;
    }

    .submit {
        height: 40px;
        min-width: 150px;
        font-family: 'ActualM';
        border-radius: 4px;
        border: none;
        color: #fff;
        font-size: 14px;
        background: #48BC25;
        letter-spacing: 0;
        margin: 0 10px;
    }

    .MuiOutlinedInput-root .primary-use {
        height: 26px;
    }

    .MuiAccordionSummary-content.Mui-expanded {
        margin: 0px
    }

    .Icon {
        margin-left: 7px;
    }

    #demo-simple-select-label {
        margin-top: -15px;
        color: rgba(0, 0, 0, 0.4);
        font-size: 14px;
        font-family: 'ActualR';
    }

    .MuiOutlinedInput-input {
        padding: 3px;
    }

    #add {
        width: 299px;
        border-radius: 4px;
        border: 1px solid rgb(207, 207, 207);
    }

    .buttonremove {
        margin-left: 6px;
    }

    .all-field {
        text-align: center;
     
        font-size: 12px;
        color: red;
    }

    .editinstaller-component label {
        font-weight: none !important;

    }
    .error{
        margin-top: 9px;
    padding-left: 7px;
    color: red;
    font-family: 'ActualR';
    }
    .error_2nd{
        margin-top: 28px;
        padding-left: 7px;
        color: red;
        font-family: 'ActualR';
    }
    .error_3nd{
        margin-top: 8px;
        padding-left: 7px;
        color: red;
        font-family: 'ActualR';
    }
    .title{
        padding-bottom:8px ;
    }
    .second_dropdown_1 {
        margin-top: 0px;

        .dropdown_1 {
            margin-right: 4px;
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 100%;
            .MuiSelect-select {
                padding:8px !important;
            }
        }

        .MuiFormControl-root {
            width: 100%;
        }

        .MuiFilledInput-root {
            background-color: #FFFFFF;
            width: 100%;
            font-family: 'ActualR';
            font-size: 14px;
            border-bottom: 1px solid #FFFFFF;

        }



        .MuiFilledInput-root:before {
            border-bottom: 1px solid #FFFFFF;

        }

        .MuiFilledInput-root:after {
            border-bottom: 1px solid #FFFFFF;
            background-color: #FFFFFF;

        }
        // .MuiSelect-select-MuiInputBase-input-MuiFilledInput-input{
        //     padding-top: 0px !important;
        //     padding-bottom: 0px!important;
        // }
        .MuiSelect-select.MuiInputBase-input-MuiFilledInput-input{
            padding: 8px !important;
           
        }
        #demo-simple-select-standard-label{
            position: absolute;
            left: 0px;
            top: -9px;
        }

        #demo-simple-select-standard{
            padding:5px;
        }
     


    }
}


